// material-ui
import { IconButton } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';

const ReportIcon = () => {
  const animationStyles = {
    animationDuration: '1.5s',
    animationName: 'infinite-rotate',
    animationIterationCount: 'infinite',
    animationDirection: 'reverse'
  };

  // TODO: Replace with redux state
  const isGenerating = false;
  const hasErrors = false;
  // !NOTE: when generating a report, this will turn to true
  // !NOTE: we'll also declare an error state to let us know of any errors generating the report
  // !NOTE: when do we reset that error state? we'll figure this out

  return (
    <>
      {!hasErrors && (
        <IconButton
          disabled
          disableRipple
          sx={{
            '&.MuiIconButton-root': {
              ...(isGenerating && animationStyles)
            },
            '&.Mui-disabled': {
              color: (theme) => (!isGenerating ? theme.palette.grey[600] : theme.palette.success.dark)
            },
            '@keyframes infinite-rotate': {
              to: {
                transform: 'rotate(360deg)'
              }
            }
          }}
        >
          <CachedIcon />
        </IconButton>
      )}
      {hasErrors && (
        <IconButton
          disabled
          disableRipple
          sx={{
            '&.Mui-disabled': {
              color: (theme) => theme.palette.error.main
            }
          }}
        >
          <ReportGmailerrorredIcon />
        </IconButton>
      )}
    </>
  );
};

export default ReportIcon;
