export const getStateColor = (state) => {
  if (!state) {
    return;
  }

  const STATE_COLOR = {
    active: '#03c04a',
    inactive: '#bdbdbd',
    cancelled: '#ff0800'
  };

  return STATE_COLOR[state];
};
