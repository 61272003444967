// project import
import other from './other';
import bottom from './bottom';
import admin from './admin';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [admin, other, bottom]
};

export default menuItems;
