import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Typography, Grid, useMediaQuery, Box, IconButton } from '@mui/material';

// project imports
import HeaderContent from 'layout/MainLayout/Header/HeaderContent';
import { HeaderSelectors } from './HeaderContent/Selectors';

// assets
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

// ==============================|| SAMPLE PAGE ||============================== //

export const Header = ({ open, handleDrawerToggle }) => {
  const menu = useSelector((state) => state.menu);
  const { openItem } = menu;
  const currentPage = openItem[0];

  const theme = useTheme();
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'grey.100';

  return (
    <>
      <Grid
        container
        sx={{
          display: 'flex',
          alignItems: 'center',
          px: 0,
          py: 2,
          backgroundColor: '#fafafb'
        }}
        {...(matchesXs && { justifyContent: 'space-between' })}
      >
        <Grid item sx={{ px: 2, flex: 1 }}>
          <Box sx={{ display: 'flex' }}>
            {matchesXs && (
              <IconButton
                onClick={handleDrawerToggle}
                sx={{
                  width: open ? 'auto' : 35,
                  height: 35,
                  mr: 1,
                  color: 'text.primary',
                  bgcolor: iconBackColor
                }}
                color="secondary"
                variant="light"
              >
                {!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              </IconButton>
            )}
            <Typography
              variant="h1"
              sx={{
                textTransform: 'capitalize',
                fontSize: '1.75rem',
                marginRight: 2
              }}
            >
              {currentPage}
            </Typography>
          </Box>
        </Grid>
        {!matchesXs && (
          <Grid
            container
            item
            sx={{
              flex: 1,
              '& > :not(:last-child)': { marginLeft: 'auto', marginRight: 2 },
              flexShrink: 0
            }}
          >
            <HeaderSelectors />
          </Grid>
        )}
        <Grid
          item
          sx={{
            width: {
              xs: 'auto',
              md: '260px'
            },
            display: 'flex',
            justifyContent: 'flex-end',
            px: 2
          }}
        >
          <HeaderContent />
        </Grid>
      </Grid>
    </>
  );
};

Header.propTypes = {
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func.isRequired
};
