import PropTypes from 'prop-types';

// material-ui
import { Box, Switch, FormControlLabel, Button, Stack } from '@mui/material';

// project import
import { DataTable } from 'components/DataTable';

// TODO: Add pagination once we know how to determine total number of pages
// TODO: Add props validation
export const TableSelection = ({ onSelect, onToggle, onAdd, isActive, switchLabel, btnLabel, data, columns, rowSelected, children }) => {
  return (
    <Box>
      <Box sx={{ height: 350 }}>
        <DataTable
          data={data}
          columns={columns}
          displayGlobalFilter={false}
          hideFilterRow
          onSelect={(selected) => onSelect(selected?.original ?? null)}
          disableMultiRowSelect
          rowSelected={rowSelected}
          withDefaultRowSelection={false}
        />
      </Box>
      <Stack>
        <FormControlLabel
          sx={{
            '&.MuiFormControlLabel-root': {
              margin: '8px 16px 8px 16px'
            }
          }}
          control={<Switch id="switch" checked={isActive} onChange={onToggle} />}
          label={switchLabel}
        />
        <Button variant="contained" onClick={onAdd}>
          {btnLabel}
        </Button>
        {children}
      </Stack>
    </Box>
  );
};

TableSelection.propTypes = {
  onSelect: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  onAdd: PropTypes.func.isRequired,
  switchLabel: PropTypes.string.isRequired,
  btnLabel: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  rowSelected: PropTypes.object, // entity should match the row entity in table
  children: PropTypes.node // optional extra options
};
