import { useSelector } from 'react-redux';

// material-ui
import { Box, Typography } from '@mui/material';

// project import
import NavGroup from './NavGroup';
import menuItem from 'menu-items';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const menu = useSelector((state) => state.menu);
  const { drawerOpen } = menu;

  // TODO: This value will come from authenticated user info
  const isAdmin = false;

  const navGroups = menuItem.items
    .filter(({ isAdminOnly }) => (isAdmin ? true : !isAdminOnly))
    .map((item) => {
      switch (item.type) {
        case 'group':
          return <NavGroup key={item.id} item={item} />;
        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Fix - Navigation Group
            </Typography>
          );
      }
    });

  return (
    <Box
      sx={{
        pt: drawerOpen ? 2 : 0,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        '& > ul:last-of-type': { mt: 'auto' }
      }}
    >
      {navGroups}
    </Box>
  );
};

export default Navigation;
