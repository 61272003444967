const jsonToFormData = (obj) => {
  const formData = new FormData();

  Object.entries(obj).forEach(([key, val]) => {
    if (Array.isArray(val)) {
      val.forEach((item) => {
        formData.append(key, item);
      });
    } else {
      formData.append(key, val);
    }
  });

  return formData;
};

export const uploadRequest = (_api, config) => {
  if (config.formData === true) {
    config.headers['Content-Type'] = 'multipart/form-data';
    config.data = jsonToFormData(config.data);
  }

  return config;
};
