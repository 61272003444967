import { useState, useMemo, useContext } from 'react';

// redux
import { useSelector } from 'react-redux';
import { selectAllProjects } from 'store/reducers/project';
import { selectAllProfiles } from 'store/reducers/profiles';

// material-ui
import { Chip, Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import TurnLeftIcon from '@mui/icons-material/TurnLeft';

// project imports
import { PopoverMenu } from 'components/PopoverMenu';
import { PopoverValue } from 'components/PopoverValue';
import { TableSelection } from 'components/ui/TableSelection';
import { HeaderContext } from 'contexts/HeaderContext';
import { Modal } from 'components/Modal';

// third-party
import moment from 'moment';

// constant
const CHIP_WIDTH = 100;

const HeaderSelectors = () => {
  const { project, assignee, onProjectChange, onAssigneeChange, onClearProject } = useContext(HeaderContext);

  const projects = useSelector(selectAllProjects);
  const profiles = useSelector(selectAllProfiles);

  const [wwsrAnchorEl, setWWSRAnchorEl] = useState(null);
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);

  const [stagedProfile, setStagedProfile] = useState(assignee);
  const [showWarningModal, setShowWarningModal] = useState(false);

  const [showActiveOnly, setShowActiveOnly] = useState(false);
  const [showActiveProfilesOnly, setShowActiveProfilesOnly] = useState(false);

  const filteredProjects = useMemo(() => {
    if (showActiveOnly) {
      // TODO: Verify if archived_at being null means project is active
      return projects.filter(({ archived_at }) => !archived_at);
    }

    return projects;
  }, [showActiveOnly, projects]);

  const filteredProfiles = useMemo(() => {
    if (showActiveProfilesOnly) {
      // TODO: Verify if archived_at being null means user is active
      // TODO: Update this logic to filter out those that are not supers
      return profiles.filter(({ archived_at }) => !archived_at);
    }

    return profiles;
  }, [showActiveProfilesOnly, profiles]);

  const projectColumns = useMemo(
    () => [
      {
        Header: 'UCC',
        accessor: 'ucc_id',
        disableFilters: true
      },
      {
        Header: 'Start Date',
        // TODO: Verify if this is created_at property
        accessor: 'start_date',
        // eslint-disable-next-line
        Cell: ({ value }) => moment(value).format('MMM DD YYYY'),
        disableFilters: true
      },
      {
        // TODO: Currently not present in data
        Header: 'Status',
        accessor: 'state',
        // eslint-disable-next-line
        Cell: ({ value }) => {
          if (value === 'cancelled') {
            return (
              <Chip
                sx={{ width: `${CHIP_WIDTH}px` }}
                color="error"
                label="Cancelled"
                size="small"
                variant="light"
                icon={<CloseIcon size="small" />}
              />
            );
          }

          if (value === 'inactive') {
            return (
              <Chip sx={{ width: `${CHIP_WIDTH}px` }} label="Inactive" size="small" variant="light" icon={<TurnLeftIcon size="small" />} />
            );
          }

          return (
            <Chip
              sx={{ width: `${CHIP_WIDTH}px` }}
              color="success"
              label="Active"
              size="small"
              variant="light"
              icon={<CheckIcon size="small" />}
            />
          );
        },
        disableFilters: true
      }
    ],
    []
  );

  const profilesColumns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'employee_id',
        disableFilters: true
      },
      // TODO: Currently not present in data
      {
        // TODO: Verify if this is created_at property
        Header: 'Start Date',
        accessor: 'start_date',
        // eslint-disable-next-line
        Cell: ({ value }) => moment(value).format('MMM DD YYYY'),
        disableFilters: true
      },
      {
        // TODO: Currently not present in data
        // TODO: We'll also need to handle inactive status. Once we know how to determine that state, refactor logic
        Header: 'Status',
        accessor: 'state',
        // eslint-disable-next-line
        Cell: ({ value }) => {
          if (value === 'cancelled') {
            return (
              <Chip
                sx={{ width: `${CHIP_WIDTH}px` }}
                color="error"
                label="Cancelled"
                size="small"
                variant="light"
                icon={<CloseIcon size="small" />}
              />
            );
          }

          if (value === 'inactive') {
            return (
              <Chip sx={{ width: `${CHIP_WIDTH}px` }} label="Inactive" size="small" variant="light" icon={<TurnLeftIcon size="small" />} />
            );
          }

          return (
            <Chip
              sx={{ width: `${CHIP_WIDTH}px` }}
              color="success"
              label="Active"
              size="small"
              variant="light"
              icon={<CheckIcon size="small" />}
            />
          );
        },
        disableFilters: true
      }
    ],
    []
  );

  return (
    <>
      <PopoverMenu
        anchorEl={wwsrAnchorEl}
        value={
          <PopoverValue
            status={project?.state}
            value={project?.ucc_id}
            placeholder="Select Project"
            onClick={(el) => setWWSRAnchorEl(el)}
          />
        }
        onClose={() => setWWSRAnchorEl(null)}
      >
        {/* figure out how we can persist this selection */}
        <TableSelection
          onSelect={(wwsr) => {
            onProjectChange(wwsr);
            setWWSRAnchorEl(null);
          }}
          onAdd={() => console.log('ADD')}
          data={filteredProjects}
          columns={projectColumns}
          onToggle={() => setShowActiveOnly(!showActiveOnly)}
          switchLabel="Active Only"
          btnLabel="Add New"
          isActive={showActiveOnly}
          rowSelected={project}
        >
          <Button
            variant="contained"
            sx={{ mt: 1, backgroundColor: '#ed3c39', '&:hover': { backgroundColor: '#ef5350' } }}
            onClick={() => {
              onClearProject();
              setWWSRAnchorEl(null);
            }}
          >
            Clear Project Selection
          </Button>
        </TableSelection>
      </PopoverMenu>
      <PopoverMenu
        anchorEl={profileAnchorEl}
        // TODO: Assignee.state currently does not exist in data
        value={
          <PopoverValue
            status={assignee?.state}
            value={assignee?.full_name}
            placeholder="Select User"
            onClick={(el) => setProfileAnchorEl(el)}
          />
        }
        onClose={() => setProfileAnchorEl(null)}
      >
        <TableSelection
          onSelect={(profile) => setStagedProfile(profile)}
          onAdd={() => {
            if (stagedProfile.employee_id !== assignee.employee_id) {
              setShowWarningModal(true);
            } else {
              setProfileAnchorEl(null);
            }
          }}
          onToggle={() => setShowActiveProfilesOnly(!showActiveProfilesOnly)}
          data={filteredProfiles}
          columns={profilesColumns}
          switchLabel="Supers Only"
          btnLabel="Assign"
          isActive={showActiveProfilesOnly}
          rowSelected={stagedProfile}
        />
      </PopoverMenu>
      <Modal
        open={showWarningModal}
        onAccept={() => {
          onAssigneeChange(stagedProfile);
          setShowWarningModal(false);
          setProfilesAnchorEl(null);
        }}
        onCancel={() => {
          // reset to current valu. if there's a current assignee, leave it at that
          setStagedProfile(assignee ?? null);
          setShowWarningModal(false);
          // leave the selector open
        }}
        title={`Warning: Project Reassignment`}
        message={`This action will re-assign this project to ${stagedProfile?.full_name}. Are you sure you want to continue?`}
      />
    </>
  );
};

export { HeaderSelectors };
