import PropTypes from 'prop-types';

// material-ui
import { Dialog, DialogTitle, DialogActions, DialogContent, Button } from '@mui/material';

export const Modal = ({ open, onAccept, onCancel, title, message }) => {
  return (
    <Dialog open={open} onClose={onCancel} sx={{ '& .MuiPaper-root': { maxWidth: 400 } }}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button onClick={onAccept} sx={{ color: '#ed3c39', '&:hover': { color: '#ef5350' } }}>
          Confirm
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

Modal.propTypes = {
  open: PropTypes.bool,
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  message: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired
};
