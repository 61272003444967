import { webApi } from '_api/config';

class ProfileApi {
  getProfiles = async () => await webApi({ auth: true }).get('/profiles/');
  createProfile = async ({ profileData }) => await webApi({ auth: true }).post('/profiles/', profileData, { formData: true });
  updateProfile = async ({ fingerprint, profileData }) =>
    await webApi({ auth: true }).patch(`/profiles/${fingerprint}/`, profileData, { formData: true });
}

export const profileApi = new ProfileApi();
