import PropTypes from 'prop-types';

// material-ui
import { Popover, Box } from '@mui/material';

export const PopoverMenu = ({ anchorEl, onClose, value, children, ...props }) => {
  return (
    <Box {...props}>
      {value}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        sx={{
          '.MuiPopover-paper': {
            minWidth: 250,
            maxWidth: 400,
            marginTop: 1
          }
        }}
      >
        {children}
      </Popover>
    </Box>
  );
};

PopoverMenu.propTypes = {
  value: PropTypes.node,
  children: PropTypes.any,
  anchorEl: PropTypes.object,
  onClose: PropTypes.func.isRequired
};
