// third-party
import { FormattedMessage } from 'react-intl';

// assets
import DashboardIcon from '@mui/icons-material/Dashboard';
import AddchartIcon from '@mui/icons-material/Addchart';
import SplitscreenIcon from '@mui/icons-material/Splitscreen';
import ArticleIcon from '@mui/icons-material/Article';
import TaskIcon from '@mui/icons-material/Task';

// icons
const icons = {
  DashboardIcon,
  AddchartIcon,
  SplitscreenIcon,
  ArticleIcon,
  TaskIcon
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const other = {
  id: 'other',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="dashboard" />,
      type: 'item',
      url: '/dashboard',
      icon: icons.DashboardIcon
    },
    {
      id: 'reports',
      title: <FormattedMessage id="reports" />,
      type: 'item',
      url: '/reports',
      icon: icons.AddchartIcon
    },
    // {
    //   id: 'tracking',
    //   title: <FormattedMessage id="tracking" />,
    //   type: 'item',
    //   url: '/tracking',
    //   icon: icons.TaskIcon
    // },
    {
      id: 'groups',
      title: <FormattedMessage id="groups" />,
      type: 'item',
      url: '/groups',
      icon: icons.ArticleIcon
    }
    // {
    //   id: 'projects',
    //   title: <FormattedMessage id="projects" />,
    //   type: 'collapse',
    //   url: '/projects',
    //   icon: icons.SplitscreenIcon,
    //   children: [
    //     {
    //       id: 'project-sub-link-1',
    //       title: <FormattedMessage id="project-sub-link-1" />,
    //       type: 'item',
    //       url: '/projects/project-sub-link-1'
    //     },
    //     {
    //       id: 'project-sub-link-2',
    //       title: <FormattedMessage id="project-sub-link-2" />,
    //       type: 'item',
    //       url: '/projects/project-sub-link-2'
    //     },
    //     {
    //       id: 'project-sub-link-3',
    //       title: <FormattedMessage id="project-sub-link-3" />,
    //       type: 'item',
    //       url: '/projects/project-sub-link-3'
    //     }
    //   ]
    // }
  ]
};

export default other;
