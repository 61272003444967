import { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { Box, Button, Popover } from '@mui/material';

// project-import
import { Form } from 'components/Form';
import { HeaderContext } from 'contexts/HeaderContext';

// redux
import { useSelector, useDispatch } from 'store';
import { patchProject } from 'store/reducers/project';
import { getDistricts, selectDistricts, getRegions, selectRegions, getCityCodes, selectCityCodes } from 'store/reducers/helpers';

// hooks
import useForm from 'hooks/useForm';

export const SideForm = ({ isMobile }) => {
  const dispatch = useDispatch();

  const { project } = useContext(HeaderContext);
  const districtOptions = useSelector(selectDistricts);
  const regionOptions = useSelector(selectRegions);
  const cityCodeOptions = useSelector(selectCityCodes);

  const [projectDetails, onChange, onBlur] = useForm(project, patchProject);
  const [anchorEl, setAnchorEl] = useState(null);

  const disableForm = !project;

  const handleBlur = (field, val) => {
    onBlur({ fingerprint: project.fingerprint, [field]: val });
  };

  // commented out fields are not yet present in project data
  const config = [
    {
      inputType: 'number',
      label: 'PR',
      value: projectDetails?.pr_number,
      onChange: (val) => onChange('pr_number', val),
      onBlur: (val) => handleBlur('pr_number', val)
    },
    {
      inputType: 'number',
      label: 'PO',
      value: projectDetails?.po_number,
      onChange: (val) => onChange('po_number', val),
      onBlur: (val) => handleBlur('po_number', val)
    },
    {
      inputType: 'text',
      label: 'Address',
      value: projectDetails?.location,
      onChange: (val) => onChange('location', val),
      onBlur: (val) => handleBlur('location', val)
    },
    // {
    //   inputType: 'date',
    //   label: 'IMO Received',
    //   value: projectDetails?.imo_received,
    //   onChange: (val) => onChange('imo_received', val),
    //   onBlur: (val) => handleBlur('imo_received', val)
    // },
    // {
    //   inputType: 'date',
    //   label: 'SCE Start',
    //   value: projectDetails?.sce_start,
    //   onChange: (val) => onChange('sce_start', val),
    //   onBlur: (val) => handleBlur('sce_start', val)
    // },
    // {
    //   inputType: 'date',
    //   label: 'Required Completion',
    //   value: projectDetails?.required_completion,
    //   onChange: (val) => onChange('required_completion', val),
    //   onBlur: (val) => handleBlur('required_completion', val)
    // },
    // {
    //   inputType: 'number',
    //   label: 'IUUF',
    //   value: projectDetails?.iuuf,
    //   onChange: (val) => onChange('iuuf', val),
    //   onBlur: (val) => handleBlur('iuuf', val)
    // },
    // NOTE: Will be brought back later
    // {
    //   inputType: 'select',
    //   label: 'Job Number',
    //   value: JOB_NUM_OPTIONS.find((job) => job.fingerprint === projectDetails?.job_number)?.fingerprint ?? '',
    //   onChange: (val) => onChange('job_number', val),
    //   onBlur: (val) => handleBlur('job_number', val),
    //   options: JOB_NUM_OPTIONS.map((job) => ({ name: job.job_number, id: job.fingerprint }))
    // },
    {
      inputType: 'text',
      label: 'Job Number',
      value: projectDetails?.job_number,
      onChange: (val) => onChange('job_number', val),
      onBlur: (val) => handleBlur('job_number', val)
    },
    {
      inputType: 'select',
      label: 'Region',
      value: regionOptions.find((region) => region.value === projectDetails?.region)?.value ?? '',
      onChange: (val) => onChange('region', val),
      onBlur: (val) => handleBlur('region', val),
      options: regionOptions.map((region) => ({ name: region.value, id: region.value }))
    },
    // {
    //   inputType: 'text',
    //   label: 'Group',
    //   value: projectDetails?.group,
    //   onChange: (val) => onChange('group', val),
    //   onBlur: (val) => handleBlur('group', val)
    // },
    {
      inputType: 'select',
      label: 'District',
      value: districtOptions.find((district) => district.value === projectDetails?.district)?.value ?? '',
      onChange: (val) => onChange('district', val),
      onBlur: (val) => handleBlur('district', val),
      options: districtOptions.map((district) => ({ name: district.value, id: district.value }))
    },
    {
      inputType: 'text',
      label: 'City',
      value: projectDetails?.city,
      onChange: (val) => onChange('city', val),
      onBlur: (val) => handleBlur('city', val)
    },
    {
      inputType: 'select',
      label: 'City Code',
      value: cityCodeOptions.find((cityCode) => cityCode.value === projectDetails?.city_code)?.value ?? '',
      onChange: (val) => onChange('city_code', val),
      onBlur: (val) => handleBlur('city_code', val),
      options: cityCodeOptions.map((cityCode) => ({ name: cityCode.value, id: cityCode.value }))
    },
    {
      inputType: 'text',
      label: 'City Permit Number',
      value: projectDetails?.city_permit_number,
      onChange: (val) => onChange('city_permit_number', val),
      onBlur: (val) => handleBlur('city_permit_number', val)
    },
    {
      inputType: 'date',
      label: 'Expiration Date',
      value: projectDetails?.expiration_date,
      onChange: (val) => onChange('expiration_date', val),
      onBlur: (val) => handleBlur('expiration_date', val)
    },
    {
      inputType: 'date',
      label: 'Gas Completion Date',
      value: projectDetails?.gas_completion_date,
      onChange: (val) => onChange('gas_completion_date', val),
      onBlur: (val) => handleBlur('gas_completion_date', val)
    },
    {
      inputType: 'text',
      label: 'USA Number',
      value: projectDetails?.usa_number,
      onChange: (val) => onChange('usa_number', val),
      onBlur: (val) => handleBlur('usa_number', val)
    },
    {
      inputType: 'text',
      label: 'Work Description',
      value: projectDetails?.work_description,
      onChange: (val) => onChange('work_description', val),
      onBlur: (val) => handleBlur(val)
    },
    {
      inputType: 'text',
      label: 'WR Number',
      value: projectDetails?.wr_number,
      onChange: (val) => onChange('wr_number', val),
      onBlur: (val) => handleBlur(val)
    }
  ].map((c) => ({ ...c, disabled: disableForm }));

  useEffect(() => {
    dispatch(getDistricts());
    dispatch(getRegions());
    dispatch(getCityCodes());
  }, [dispatch]);

  if (isMobile) {
    return (
      <Box sx={{ width: '100%' }}>
        <Button
          fullWidth
          onClick={(ev) => setAnchorEl(ev.currentTarget)}
          sx={{ p: 2, background: '#fafafb' }}
          disableRipple
          disableTouchRipple
          disableFocusRipple
        >
          Project Details
        </Button>
        <Popover
          sx={{ '& .MuiPaper-root': { mt: 0.5, width: '100%', maxHeight: '500px', boxShadow: (theme) => theme.shadows[2] } }}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <Form
            sx={{
              p: 2,
              backgroundColor: '#fafafb',
              height: '100%'
            }}
            config={config}
          />
        </Popover>
      </Box>
    );
  }

  return (
    <Form
      sx={{
        p: 2,
        backgroundColor: '#fafafb',
        height: '100%',
        overflow: 'auto'
      }}
      config={config}
    />
  );
};

SideForm.propTypes = {
  isMobile: PropTypes.bool
};
