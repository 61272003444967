import PropTypes from 'prop-types';
import { useMemo } from 'react';

// material-ui
import { alpha, useTheme } from '@mui/material/styles';
import { Box, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

// third-party
import { useTable, useFilters, useGlobalFilter, useRowSelect, useMountedLayoutEffect } from 'react-table';

// project import
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';
import { GlobalFilter, DefaultColumnFilter, renderFilterTypes, IndeterminateCheckbox } from 'utils/react-table';

// ==============================|| REACT TABLE ||============================== //

export const DataTable = ({
  columns,
  data,
  styles,
  onSelect,
  hiddenColumns,
  displayGlobalFilter = true,
  hideFilterRow = false,
  disableMultiRowSelect = false,
  rowSelected,
  withDefaultRowSelection = true
}) => {
  const theme = useTheme();
  const filterTypes = useMemo(() => renderFilterTypes, []);
  const defaultColumn = useMemo(() => ({ Filter: DefaultColumnFilter }), []);
  const initialState = useMemo(
    () => ({ filters: [{ id: 'status', value: '' }], ...(hiddenColumns && { hiddenColumns }) }),
    [hiddenColumns]
  );

  const rowSelectArgs = [
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          accessor: 'Selection',
          // eslint-disable-next-line
          Header: ({ getToggleAllRowsSelectedProps, toggleAllRowsSelected }) => {
            if (!disableMultiRowSelect) {
              return <IndeterminateCheckbox indeterminate {...getToggleAllRowsSelectedProps()} />;
            }

            const { indeterminate, checked } = getToggleAllRowsSelectedProps();
            const someRowSelected = indeterminate && !checked;

            return (
              <IndeterminateCheckbox
                {...getToggleAllRowsSelectedProps()}
                checked={someRowSelected}
                onChange={() => {
                  if (someRowSelected) {
                    toggleAllRowsSelected(false);
                  }

                  // no-op
                  return;
                }}
              />
            );
          },
          // eslint-disable-next-line
          Cell: ({ row, toggleAllRowsSelected }) => {
            return (
              <IndeterminateCheckbox
                // eslint-disable-next-line
                {...row.getToggleRowSelectedProps()}
                {...(disableMultiRowSelect && {
                  onChange: () => {
                    toggleAllRowsSelected(false);

                    // eslint-disable-next-line
                    if (!row.isSelected) {
                      // eslint-disable-next-line
                      row.toggleRowSelected();
                    }
                  }
                })}
              />
            );
          },
          disableFilters: true
        },
        ...columns
      ]);
    }
  ];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // @ts-ignore
    preGlobalFilteredRows,
    // @ts-ignore
    setGlobalFilter,
    // @ts-ignore
    state: { globalFilter },
    selectedFlatRows
  } = useTable(
    {
      columns,
      data,
      // @ts-ignore
      defaultColumn,
      // @ts-ignore
      initialState,
      filterTypes
    },
    useGlobalFilter,
    useFilters,
    ...(withDefaultRowSelection ? rowSelectArgs : [])
  );

  useMountedLayoutEffect(() => {
    if (!disableMultiRowSelect) {
      onSelect && onSelect(selectedFlatRows);
    }
  }, [onSelect, selectedFlatRows]);

  // uncomment later when we decide on how many rows we want per page
  // const sortingRow = rows.slice(0, 10);

  return (
    <MainCard content={false} sx={{ height: '100%' }}>
      <ScrollX sx={{ height: '100%' }}>
        <Stack spacing={2} sx={{ ...styles }}>
          {displayGlobalFilter ? (
            <Box sx={{ p: 2, pb: 0 }}>
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                // @ts-ignore
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </Box>
          ) : null}
          <Table {...getTableProps()}>
            <TableHead sx={{ borderTopWidth: 2 }}>
              {headerGroups.map((headerGroup, i) => (
                <TableRow key={i} {...headerGroup.getHeaderGroupProps()} sx={{ '& > th:first-of-type': { width: '58px' } }}>
                  {headerGroup.headers.map((column, index) => (
                    <TableCell key={index} {...column.getHeaderProps([{ className: column.className }])}>
                      {column.render('Header')}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {!hideFilterRow &&
                headerGroups.map((group, i) => (
                  <TableRow key={i} {...group.getHeaderGroupProps()}>
                    {group.headers.map((column, index) => (
                      <TableCell key={index} {...column.getHeaderProps([{ className: column.className }])}>
                        {column.canFilter ? column.render('Filter') : null}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              {/* TODO: Decide how many rows we want per page. Add Pagination as well */}
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <TableRow
                    key={i}
                    {...row.getRowProps()}
                    sx={{
                      cursor: 'pointer',
                      bgcolor: row.isSelected ? alpha(theme.palette.primary.lighter, 0.35) : 'inherit'
                    }}
                    {...(disableMultiRowSelect && {
                      onClick: () => onSelect(row),
                      selected: rowSelected?.fingerprint === row.original.fingerprint
                    })}
                  >
                    {row.cells.map((cell, index) => (
                      <TableCell key={index} {...cell.getCellProps([{ className: cell.column.className }])}>
                        {cell.render('Cell')}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Stack>
      </ScrollX>
    </MainCard>
  );
};

DataTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  styles: PropTypes.object,
  onSelect: PropTypes.func,
  selectedIds: PropTypes.object,
  displayGlobalFilter: PropTypes.bool,
  hiddenColumns: PropTypes.arrayOf(PropTypes.string),
  hideFilterRow: PropTypes.bool,
  disableMultiRowSelect: PropTypes.bool,
  rowSelected: PropTypes.object,
  withDefaultRowSelection: PropTypes.bool
};
