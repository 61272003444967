import { createSlice, createEntityAdapter, createAsyncThunk } from '@reduxjs/toolkit';

// project imports
import { profileApi } from '_api/profiles';

// constants
import { LOADING_STATE } from 'utils/constants';

// adapter
export const profileAdapter = createEntityAdapter();

const slice = createSlice({
  name: 'profiles',
  initialState: profileAdapter.getInitialState({
    loading: LOADING_STATE.IDLE,
    error: null,
    next: null,
    prev: null,
    count: null
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProfiles.pending, (state) => {
      state.error = null;
      state.loading = LOADING_STATE.PENDING;
    });
    builder.addCase(getProfiles.fulfilled, (state, action) => {
      state.error = null;
      state.loading = LOADING_STATE.IDLE;

      const { results, next, previous, count } = action.payload;

      profileAdapter.setAll(state, results);
      state.next = next;
      state.prev = previous;
      state.count = count;
    });
    builder.addCase(getProfiles.rejected, (state, action) => {
      state.loading = LOADING_STATE.IDLE;

      const { problem } = action.payload;
      state.error = problem;
    });
    builder.addCase(createProfile.fulfilled, (state, action) => {
      profileAdapter.addOne(state, { ...action.payload });
    });
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      profileAdapter.updateOne(state, { id: action.payload.fingerprint, changes: action.payload });
    });
  }
});

export default slice.reducer;

export const { selectById: selectProfileById, selectAll: selectAllProfiles } = profileAdapter.getSelectors((state) => state.profiles);

export const getProfiles = createAsyncThunk('profiles/getProfiles', async (_, { rejectWithValue }) => {
  try {
    const response = await profileApi.getProfiles();

    if (!response.ok) {
      return rejectWithValue(response.problem);
    }

    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createProfile = createAsyncThunk('profiles/createProfile', async ({ profileData }, { rejectWithValue }) => {
  try {
    const response = await profileApi.createProfile({ profileData });

    if (!response.ok) {
      return rejectWithValue(response.problem);
    }

    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateProfile = createAsyncThunk('profiles/updateProfile', async ({ fingerprint, profileData }, { rejectWithValue }) => {
  try {
    const response = await profileApi.updateProfile({ fingerprint, profileData });

    if (!response.ok) {
      return rejectWithValue(response.problem);
    }

    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});
