import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { IconButton, useMediaQuery } from '@mui/material';

// project import
import DrawerHeaderStyled from './DrawerHeaderStyled';

// assets
import { MenuFoldOutlined, MenuUnfoldOutlined, CloseCircleOutlined } from '@ant-design/icons';

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open, handleDrawerToggle }) => {
  const theme = useTheme();

  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300';
  const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'grey.100';

  return (
    <DrawerHeaderStyled theme={theme} open={open}>
      {!matchesXs && (
        <IconButton
          onClick={handleDrawerToggle}
          sx={{
            width: open ? 'auto' : 35,
            height: 35,
            color: 'text.primary',
            bgcolor: open ? iconBackColorOpen : iconBackColor
          }}
          color="secondary"
          variant="light"
        >
          {open ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
        </IconButton>
      )}
      {matchesXs && (
        <IconButton onClick={handleDrawerToggle} edge="start" sx={{ width: 35, height: 35, ml: 'auto' }}>
          <CloseCircleOutlined />
        </IconButton>
      )}
    </DrawerHeaderStyled>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func.isRequired
};

export default DrawerHeader;
