import PropTypes from 'prop-types';

// material-ui
import { Box, Typography, Button } from '@mui/material';

// utils
import { getStateColor } from 'utils/getStateColor';

export const PopoverValue = ({ status, value, placeholder, onClick }) => {
  return (
    <Button
      size="large"
      variant="outlined"
      sx={{
        border: '1px solid #ccc',
        '&:hover': {
          borderColor: '#999',
          color: '#000'
        },
        width: { xs: '100%', md: 200 },
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        display: 'inline-block',
        textOverflow: 'ellipsis',
        color: '#000'
      }}
      onClick={(ev) => onClick(ev.currentTarget)}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          '& > :not(:last-child)': {
            marginRight: 1.5
          }
        }}
      >
        {!value && (
          <Typography variant="body1" sx={{ lineHeight: 1.5 }}>
            {placeholder}
          </Typography>
        )}
        {value && (
          <>
            <Box
              sx={{
                width: 9,
                height: 9,
                backgroundColor: getStateColor(status),
                borderRadius: '50%'
              }}
            />

            <Typography variant="body1" sx={{ lineHeight: 1.5 }}>
              {value}
            </Typography>
          </>
        )}
      </Box>
    </Button>
  );
};

PopoverValue.propTypes = {
  status: PropTypes.oneOf(['active', 'inactive', 'cancelled']),
  value: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};
