import { webApi } from '_api/config';

class HelpersApi {
  getDistricts = async () => await webApi({ auth: true }).get('/helpers/district/');
  getRegions = async () => await webApi({ auth: true }).get('/helpers/region/');
  getJobPhases = async () => await webApi({ auth: true }).get('/helpers/job-phase/');
  getJobStatuses = async () => await webApi({ auth: true }).get('/helpers/job-status/');
  getElectricalPartners = async () => await webApi({ auth: true }).get('/helpers/electrical-partner/');
  getAribaUsers = async () => await webApi({ auth: true }).get('/helpers/ariba-user/');
  getInvoiceTypes = async () => await webApi({ auth: true }).get('/helpers/invoice-type/');
  getCityCodes = async () => await webApi({ auth: true }).get('/helpers/city-code/');
  getDeliveryMethods = async () => await webApi({ auth: true }).get('/helpers/delivery-methods/');
}

export const helpersApi = new HelpersApi();
