// third-party
import { FormattedMessage } from 'react-intl';

// assets
import SupportIcon from '@mui/icons-material/Support';
import SettingsIcon from '@mui/icons-material/Settings';

// icons
const icons = {
  SupportIcon,
  SettingsIcon
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const bottom = {
  id: 'bottom',
  type: 'group',
  children: [
    {
      id: 'support',
      title: <FormattedMessage id="support" />,
      type: 'item',
      url: '/support',
      icon: icons.SupportIcon
    },
    {
      id: 'settings',
      title: <FormattedMessage id="settings" />,
      type: 'item',
      url: '/settings',
      icon: icons.SettingsIcon
    }
  ]
};

export default bottom;
