import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

// project imports
import { helpersApi } from '_api/helpers';

// constants
import { LOADING_STATE } from 'utils/constants';

// entities
export const districtAdapter = createEntityAdapter({
  selectId: (d) => d.fingerprint
});

export const regionAdapter = createEntityAdapter({
  selectId: (r) => r.fingerprint
});

export const jobPhaseAdapter = createEntityAdapter({
  selectId: (jp) => jp.fingerprint
});

export const jobStatusAdapter = createEntityAdapter({
  selectId: (js) => js.fingerprint
});

export const electricalPartnerAdapter = createEntityAdapter({
  selectId: (ep) => ep.fingerprint
});

export const aribaUserAdapter = createEntityAdapter({
  selectId: (au) => au.fingerprint
});

export const invoiceTypeAdapter = createEntityAdapter({
  selectId: (it) => it.fingerprint
});

export const cityCodeAdapter = createEntityAdapter({
  selectId: (cc) => cc.fingerprint
});

export const deliveryMethodAdapter = createEntityAdapter({
  selectId: (dm) => dm.fingerprint
});

const slice = createSlice({
  name: 'helpers',
  initialState: {
    district: districtAdapter.getInitialState({
      loading: LOADING_STATE.IDLE,
      error: null,
      next: null,
      prev: null,
      count: 0
    }),
    region: regionAdapter.getInitialState({
      loading: LOADING_STATE.IDLE,
      error: null,
      next: null,
      prev: null,
      count: 0
    }),
    jobPhase: jobPhaseAdapter.getInitialState({
      loading: LOADING_STATE.IDLE,
      error: null,
      next: null,
      prev: null,
      count: 0
    }),
    jobStatus: jobStatusAdapter.getInitialState({
      loading: LOADING_STATE.IDLE,
      error: null,
      next: null,
      prev: null,
      count: 0
    }),
    electricalPartner: electricalPartnerAdapter.getInitialState({
      loading: LOADING_STATE.IDLE,
      error: null,
      next: null,
      prev: null,
      count: 0
    }),
    aribaUser: aribaUserAdapter.getInitialState({
      loading: LOADING_STATE.IDLE,
      error: null,
      next: null,
      prev: null,
      count: 0
    }),
    invoiceType: invoiceTypeAdapter.getInitialState({
      loading: LOADING_STATE.IDLE,
      error: null,
      next: null,
      prev: null,
      count: 0
    }),
    cityCode: cityCodeAdapter.getInitialState({
      loading: LOADING_STATE.IDLE,
      error: null,
      next: null,
      prev: null,
      count: 0
    }),
    deliveryMethod: deliveryMethodAdapter.getInitialState({
      loading: LOADING_STATE.IDLE,
      error: null,
      next: null,
      prev: null,
      count: 0
    })
  },
  reducers: {},
  extraReducers: (builder) => {
    // DISTRICTS
    builder.addCase(getDistricts.pending, (state) => {
      state.district.error = null;
      state.district.loading = LOADING_STATE.PENDING;
    });
    builder.addCase(getDistricts.fulfilled, (state, action) => {
      state.district.error = null;
      state.district.loading = LOADING_STATE.IDLE;

      const { next, previous, count, results } = action.payload;

      districtAdapter.setAll(state.district, results);
      state.district.next = next;
      state.district.prev = previous;
      state.district.count = count;
    });
    builder.addCase(getDistricts.rejected, (state, action) => {
      state.district.loading = LOADING_STATE.IDLE;

      const { problem } = action.payload;
      state.district.error = problem;
    });
    // REGIONS
    builder.addCase(getRegions.pending, (state) => {
      state.region.error = null;
      state.region.loading = LOADING_STATE.PENDING;
    });
    builder.addCase(getRegions.fulfilled, (state, action) => {
      state.region.error = null;
      state.region.loading = LOADING_STATE.IDLE;

      const { next, previous, count, results } = action.payload;

      regionAdapter.setAll(state.region, results);
      state.region.next = next;
      state.region.prev = previous;
      state.region.count = count;
    });
    builder.addCase(getRegions.rejected, (state, action) => {
      state.region.loading = LOADING_STATE.IDLE;

      const { problem } = action.payload;
      state.region.error = problem;
    });
    // JOB PHASES
    builder.addCase(getJobPhases.pending, (state) => {
      state.jobPhase.error = null;
      state.jobPhase.loading = LOADING_STATE.PENDING;
    });
    builder.addCase(getJobPhases.fulfilled, (state, action) => {
      state.jobPhase.error = null;
      state.jobPhase.loading = LOADING_STATE.IDLE;

      const { next, previous, count, results } = action.payload;

      jobPhaseAdapter.setAll(state.jobPhase, results);
      state.jobPhase.next = next;
      state.jobPhase.prev = previous;
      state.jobPhase.count = count;
    });
    builder.addCase(getJobPhases.rejected, (state, action) => {
      state.jobPhase.loading = LOADING_STATE.IDLE;

      const { problem } = action.payload;
      state.jobPhase.error = problem;
    });
    // JOB STATUSES
    builder.addCase(getJobStatuses.pending, (state) => {
      state.jobStatus.error = null;
      state.jobStatus.loading = LOADING_STATE.PENDING;
    });
    builder.addCase(getJobStatuses.fulfilled, (state, action) => {
      state.jobStatus.error = null;
      state.jobStatus.loading = LOADING_STATE.IDLE;

      const { next, previous, count, results } = action.payload;

      jobStatusAdapter.setAll(state.jobStatus, results);
      state.jobStatus.next = next;
      state.jobStatus.prev = previous;
      state.jobStatus.count = count;
    });
    builder.addCase(getJobStatuses.rejected, (state, action) => {
      state.jobStatus.loading = LOADING_STATE.IDLE;

      const { problem } = action.payload;
      state.jobStatus.error = problem;
    });
    // ELECTRICAL PARTNERS
    builder.addCase(getElectricalPartners.pending, (state) => {
      state.electricalPartner.error = null;
      state.electricalPartner.loading = LOADING_STATE.PENDING;
    });
    builder.addCase(getElectricalPartners.fulfilled, (state, action) => {
      state.electricalPartner.error = null;
      state.electricalPartner.loading = LOADING_STATE.IDLE;

      const { next, previous, count, results } = action.payload;

      electricalPartnerAdapter.setAll(state.electricalPartner, results);
      state.electricalPartner.next = next;
      state.electricalPartner.prev = previous;
      state.electricalPartner.count = count;
    });
    builder.addCase(getElectricalPartners.rejected, (state, action) => {
      state.electricalPartner.loading = LOADING_STATE.IDLE;

      const { problem } = action.payload;
      state.electricalPartner.error = problem;
    });
    // ARIBA USERS
    builder.addCase(getAribaUsers.pending, (state) => {
      state.aribaUser.error = null;
      state.aribaUser.loading = LOADING_STATE.PENDING;
    });
    builder.addCase(getAribaUsers.fulfilled, (state, action) => {
      state.aribaUser.error = null;
      state.aribaUser.loading = LOADING_STATE.IDLE;

      const { next, previous, count, results } = action.payload;

      aribaUserAdapter.setAll(state.aribaUser, results);
      state.aribaUser.next = next;
      state.aribaUser.prev = previous;
      state.aribaUser.count = count;
    });
    builder.addCase(getAribaUsers.rejected, (state, action) => {
      state.aribaUser.loading = LOADING_STATE.IDLE;

      const { problem } = action.payload;
      state.aribaUser.error = problem;
    });
    // INVOICE TYPES
    builder.addCase(getInvoiceTypes.pending, (state) => {
      state.invoiceType.error = null;
      state.invoiceType.loading = LOADING_STATE.PENDING;
    });
    builder.addCase(getInvoiceTypes.fulfilled, (state, action) => {
      state.invoiceType.error = null;
      state.invoiceType.loading = LOADING_STATE.IDLE;

      const { next, previous, count, results } = action.payload;

      aribaUserAdapter.setAll(state.invoiceType, results);
      state.invoiceType.next = next;
      state.invoiceType.prev = previous;
      state.invoiceType.count = count;
    });
    builder.addCase(getInvoiceTypes.rejected, (state, action) => {
      state.invoiceType.loading = LOADING_STATE.IDLE;

      const { problem } = action.payload;
      state.invoiceType.error = problem;
    });
    // CITY CODES
    builder.addCase(getCityCodes.pending, (state) => {
      state.cityCode.error = null;
      state.cityCode.loading = LOADING_STATE.PENDING;
    });
    builder.addCase(getCityCodes.fulfilled, (state, action) => {
      state.cityCode.error = null;
      state.cityCode.loading = LOADING_STATE.IDLE;

      const { next, previous, count, results } = action.payload;

      cityCodeAdapter.setAll(state.cityCode, results);
      state.cityCode.next = next;
      state.cityCode.prev = previous;
      state.cityCode.count = count;
    });
    builder.addCase(getCityCodes.rejected, (state, action) => {
      state.cityCode.loading = LOADING_STATE.IDLE;

      const { problem } = action.payload;
      state.cityCode.error = problem;
    });
    // DELIVERY METHODS
    builder.addCase(getDeliveryMethods.pending, (state) => {
      state.deliveryMethod.error = null;
      state.deliveryMethod.loading = LOADING_STATE.PENDING;
    });
    builder.addCase(getDeliveryMethods.fulfilled, (state, action) => {
      state.deliveryMethod.error = null;
      state.deliveryMethod.loading = LOADING_STATE.IDLE;

      const { next, previous, count, results } = action.payload;

      cityCodeAdapter.setAll(state.deliveryMethod, results);
      state.deliveryMethod.next = next;
      state.deliveryMethod.prev = previous;
      state.deliveryMethod.count = count;
    });
    builder.addCase(getDeliveryMethods.rejected, (state, action) => {
      state.deliveryMethod.loading = LOADING_STATE.IDLE;

      const { problem } = action.payload;
      state.deliveryMethod.error = problem;
    });
  }
});

export default slice.reducer;

export const getDistricts = createAsyncThunk('helpers/getDistricts', async (_, { rejectWithValue }) => {
  try {
    const response = await helpersApi.getDistricts();

    if (!response.ok) {
      return rejectWithValue(response.problem);
    }

    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getRegions = createAsyncThunk('helpers/getRegions', async (_, { rejectWithValue }) => {
  try {
    const response = await helpersApi.getRegions();

    if (!response.ok) {
      return rejectWithValue(response.problem);
    }

    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getJobPhases = createAsyncThunk('helpers/getJobPhases', async (_, { rejectWithValue }) => {
  try {
    const response = await helpersApi.getJobPhases();

    if (!response.ok) {
      return rejectWithValue(response.problem);
    }

    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getJobStatuses = createAsyncThunk('helpers/getJobStatuses', async (_, { rejectWithValue }) => {
  try {
    const response = await helpersApi.getJobStatuses();

    if (!response.ok) {
      return rejectWithValue(response.problem);
    }

    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getElectricalPartners = createAsyncThunk('helpers/getElectricalPartners', async (_, { rejectWithValue }) => {
  try {
    const response = await helpersApi.getElectricalPartners();

    if (!response.ok) {
      return rejectWithValue(response.problem);
    }

    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getAribaUsers = createAsyncThunk('helpers/getAribaUsers', async (_, { rejectWithValue }) => {
  try {
    const response = await helpersApi.getAribaUsers();

    if (!response.ok) {
      return rejectWithValue(response.problem);
    }

    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getInvoiceTypes = createAsyncThunk('helpers/getInvoiceTypes', async (_, { rejectWithValue }) => {
  try {
    const response = await helpersApi.getInvoiceTypes();

    if (!response.ok) {
      return rejectWithValue(response.problem);
    }

    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getCityCodes = createAsyncThunk('helpers/getCityCodes', async (_, { rejectWithValue }) => {
  try {
    const response = await helpersApi.getCityCodes();

    if (!response.ok) {
      return rejectWithValue(response.problem);
    }

    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getDeliveryMethods = createAsyncThunk('helpers/getDeliveryMethods', async (_, { rejectWithValue }) => {
  try {
    const response = await helpersApi.getDeliveryMethods();

    if (!response.ok) {
      return rejectWithValue(response.problem);
    }

    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

// selectors
export const { selectAll: selectDistricts } = districtAdapter.getSelectors((state) => state.helpers.district);
export const { selectAll: selectRegions } = regionAdapter.getSelectors((state) => state.helpers.region);
export const { selectAll: selectJobPhases } = jobPhaseAdapter.getSelectors((state) => state.helpers.jobPhase);
export const { selectAll: selectJobStatuses } = jobStatusAdapter.getSelectors((state) => state.helpers.jobStatus);
export const { selectAll: selectElectricalPartners } = electricalPartnerAdapter.getSelectors((state) => state.helpers.electricalPartner);
export const { selectAll: selectAribaUsers } = aribaUserAdapter.getSelectors((state) => state.helpers.aribaUser);
export const { selectAll: selectInvoiceTypes } = invoiceTypeAdapter.getSelectors((state) => state.helpers.invoiceType);
export const { selectAll: selectCityCodes } = cityCodeAdapter.getSelectors((state) => state.helpers.cityCode);
export const { selectAll: selectDeliveryMethods } = deliveryMethodAdapter.getSelectors((state) => state.helpers.deliveryMethod);
