/* eslint-disable no-unused-vars */

// third party
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { create } from 'apisauce';
import * as Interceptors from './interceptors';

// Web API configuration

export const webApi = ({ auth, req, res, asset } = {}) => {
  const baseApi = axios.create({
    baseURL: asset ? '' : process.env.REACT_APP_API_BASE_URL,
    responseType: asset ? 'blob' : false,
    useAuth: auth
    // headers: {
    //   'Cache-Control': 'no-cache',
    //   'Content-type': 'application/json'
    // }
  });

  baseApi.interceptors.request.use(
    (config) => {
      const accessToken = globalThis.localStorage.getItem('accessToken');
      if (config.useAuth) {
        // config.headers['Authorization'] = `Bearer ${accessToken}`;
        // config.headers['Authorization'] = `Bearer 1234`;
      }
      config = Interceptors.uploadRequest(baseApi, config);
      return config;
    },
    (error) => Promise.reject(error)
  );

  baseApi.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (originalConfig.url !== '/login' && err.response) {
        if (err.response.status === 401 && originalConfig._retry === false) {
          originalConfig._retry = true;

          try {
            // call WebAuth.checkSession() here and store token to localStorage.
            // TODO: Once this is set, check if WebAuth.checkSession() is still necessary in JWTContext
            return baseApi(originalConfig);
          } catch (_err) {
            console.log('ERR: ', err);
            return Promise.reject(_err);
          }
        }
      }

      return Promise.reject(err);
    }
  );

  axiosRetry(baseApi, { retryDelay: axiosRetry.exponentialDelay });

  return create({
    axiosInstance: baseApi,
    timeout: 20000
  });
};
