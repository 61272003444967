import PropTypes from 'prop-types';

// material-ui
import { Stack, TextField, Select, FormControl, MenuItem, InputLabel, Typography } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';

// third-party
import moment from 'moment';

export const INPUT_TYPES = {
  NUMBER: 'number',
  TEXT: 'text',
  DATE: 'date',
  SELECT: 'select'
};

export const Form = ({ config, ...props }) => {
  return (
    <Stack gap={3} {...props}>
      {config.map(({ inputType, label, value, onChange, options, ...rest }, i) => {
        if (inputType === INPUT_TYPES.DATE) {
          return (
            <DesktopDatePicker
              key={`${i}-${label}`}
              disableHighlightToday
              inputFormat="YYYY-MM-DD"
              value={value ? value : null}
              closeOnSelect
              onChange={(val) => {
                const toYYYYMMDD = moment(val).format('YYYY-MM-DD');
                onChange(toYYYYMMDD);
              }}
              renderInput={(params) => <TextField {...params} />}
              label={label}
              {...rest}
            />
          );
        }

        if (inputType === INPUT_TYPES.SELECT) {
          return (
            <FormControl key={`${i}-${label}`} fullWidth>
              <InputLabel id={`${i}-${label}`}>{label}</InputLabel>
              <Select
                fullWidth
                labelId={`${i}-${label}`}
                value={value}
                onChange={(ev) => onChange(ev.target.value)}
                label={label}
                MenuProps={{
                  sx: {
                    '.MuiPaper-root': {
                      maxHeight: 300
                    }
                  }
                }}
                {...rest}
              >
                {options.map(({ name, id }) => (
                  <MenuItem key={id} value={id}>
                    <Typography sx={{ textTransform: 'capitalize' }}>{name}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        }

        return (
          <TextField
            key={`${i}-${label}`}
            value={value ?? ''} // Empty string needed. Otherwise, the input label behaves weirdly.
            onChange={(ev) => onChange(ev.target.value)}
            label={label}
            InputLabelProps={{ shrink: !!value }}
            {...rest}
          />
        );
      })}
    </Stack>
  );
};

Form.propTypes = {
  config: PropTypes.arrayOf(
    PropTypes.shape({
      inputType: PropTypes.oneOf(Object.values(INPUT_TYPES)),
      label: PropTypes.string.isRequired,
      value: PropTypes.any,
      onChange: PropTypes.func.isRequired
    })
  )
};
