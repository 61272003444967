import { lazy } from 'react';

// pages routing
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));

// project import
import Loadable from 'components/Loadable';

// ==============================|| MAIN ROUTING ||============================== //

const NotFound = {
  path: '*',
  element: <MaintenanceError />
};

export default NotFound;
