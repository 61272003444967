// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import snackbar from './snackbar';
import calendar from './calendar';
import projects from './project';
import helpers from './helpers';
import profiles from './profiles';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  snackbar,
  calendar,
  projects,
  helpers,
  profiles
});

export default reducers;
