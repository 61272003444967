import { webApi } from '_api/config';

// import axios from 'axios';

class ProjectApi {
  // CREATE
  createProject = async ({ projectData }) => await webApi({ auth: true }).post('/projects/', projectData, { formData: true });
  // READ
  getProjects = async () => await webApi({ auth: true }).get('/projects/projects/');
  getExtendedProject = async ({ fingerprint }) => await webApi({ auth: true }).get(`/projects/projects/${fingerprint}/extend/`);

  // UPDATE
  patchProject = async ({ fingerprint, payload }) =>
    await webApi({ auth: true }).patch(`/projects/projects/${fingerprint}/`, payload, { formData: true });
  patchProjectSuperintendent = async ({ fingerprint, payload }) =>
    await webApi({ auth: true }).patch(`/projects/superintendents/${fingerprint}/`, payload, { formData: true });
  patchProjectBillingInvoice = async ({ fingerprint, payload }) =>
    await webApi({ auth: true }).patch(`/billing/billing-invoices/${fingerprint}/`, payload, { formData: true });
  patchProjectAsBuilt = async ({ fingerprint, payload }) =>
    await webApi({ auth: true }).patch(`/projects/as-built/${fingerprint}/`, payload, { formData: true });
  patchProjectJob = async ({ fingerprint, payload }) =>
    await webApi({ auth: true }).patch(`/projects/jobs/${fingerprint}/`, payload, { formData: true });
  patchProjectJobPacket = async ({ fingerprint, payload }) =>
    await webApi({ auth: true }).patch(`/projects/job-packet-returned/${fingerprint}/`, payload, { formData: true });
  patchProjectJobCosting = async ({ fingerprint, payload }) =>
    await webApi({ auth: true }).patch(`/billing/job-costing/${fingerprint}/`, payload, { formData: true });
  patchProjectFcos = async ({ fingerprint, payload }) =>
    await webApi({ auth: true }).patch(`/approvals/wmo_fco/${fingerprint}/`, payload, { formData: true });
}

export const projectApi = new ProjectApi();
