import PropTypes from 'prop-types';
import { createContext, useEffect } from 'react';

// redux
import { useSelector, useDispatch } from 'store';
import {
  getProjects,
  getExtendedProject,
  setProject,
  clearProject,
  selectActiveProject,
  selectProjectSuperintendent,
  patchProject
} from 'store/reducers/project';
import { getProfiles } from 'store/reducers/profiles';

// import { getUsers } from 'store/reducers/user';

const initialState = {
  project: null,
  assignee: null
};

const HeaderContext = createContext(initialState);

const HeaderProvider = ({ children }) => {
  const dispatch = useDispatch();

  const project = useSelector(selectActiveProject);
  const assignee = useSelector(selectProjectSuperintendent);

  const handleProjectChange = (proj) => {
    dispatch(getExtendedProject({ fingerprint: proj.fingerprint }));
  };

  const handleClearProject = () => {
    dispatch(clearProject(null));

    localStorage.removeItem('project');
    localStorage.removeItem('assignee');
  };

  const handleAssigneeChange = (user) => {
    dispatch(patchProject({ fingerprint: project.fingerprint, profile: user }));
  };

  useEffect(() => {
    const current = localStorage.getItem('project');

    if (current) {
      dispatch(setProject(JSON.parse(current)));
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(getProjects());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getProfiles());
  }, [dispatch]);

  return (
    <HeaderContext.Provider
      value={{
        onProjectChange: handleProjectChange,
        onAssigneeChange: handleAssigneeChange,
        onClearProject: handleClearProject,
        project,
        assignee
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};

HeaderProvider.propTypes = {
  children: PropTypes.node
};

export { HeaderContext, HeaderProvider };
