export const REPORT_TYPES = {
  SUMMARY: 'summary',
  DETAILS: 'details',
  ANALYSIS: 'job_analysis'
};

export const REPORT_LABEL_MAP = {
  [REPORT_TYPES.ANALYSIS]: 'Job Analysis',
  [REPORT_TYPES.DETAILS]: 'Details',
  [REPORT_TYPES.SUMMARY]: 'Summary'
};

export const LOADING_STATE = {
  IDLE: 'idle',
  PENDING: 'pending'
};

export const CURRENCY = {
  USD: { option: 'usd', symbol: '$' },
  EUR: { option: 'eur', symbol: '€' }
};
