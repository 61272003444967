import { createSlice, createEntityAdapter, createAsyncThunk, createSelector } from '@reduxjs/toolkit';

// project imports
import { projectApi } from '_api/projects';

// constants
import { LOADING_STATE } from 'utils/constants';

// adapter
export const projectAdapter = createEntityAdapter({
  selectId: (p) => p.fingerprint
});

// utils
const getUpdatedProjectFromStorage = (target, value) => {
  const currentProject = JSON.parse(localStorage.getItem('project'));
  return {
    ...currentProject,
    [target]: value
  };
};

const slice = createSlice({
  name: 'projects',
  initialState: projectAdapter.getInitialState({
    loading: LOADING_STATE.IDLE,
    error: null,
    next: null,
    prev: null,
    project: null,
    projectSuperintendents: [],
    projectAsBuilts: [],
    projectBilling: [],
    projectJobs: [],
    projectJobPacket: [],
    projectJobCosting: [],
    projectFcos: []
  }),
  reducers: {
    setProject(state, action) {
      const { as_builts, billing, job_packet_returned, superintendents, wmo_fco, project_jobs, job_costing } = action.payload;

      state.project = action.payload;
      state.projectAsBuilts = as_builts;
      state.projectBilling = billing;
      state.projectJobPacket = job_packet_returned;
      state.projectSuperintendents = superintendents;
      state.projectFcos = wmo_fco;
      state.projectJobs = project_jobs;
      state.projectJobCosting = job_costing;
    },
    clearProject: (state) => {
      state.project = null;
      state.projectSuperintendents = null;
      state.projectAsBuilts = null;
      state.projectBilling = null;
      state.projectJobs = null;
      state.projectJobPacket = null;
      state.projectJobCosting = null;
      state.projectFcos = null;
    }
  },
  extraReducers: (builder) => {
    // READ
    builder.addCase(getProjects.pending, (state) => {
      state.error = null;
      state.loading = LOADING_STATE.PENDING;
    });
    builder.addCase(getProjects.fulfilled, (state, action) => {
      state.error = null;
      state.loading = LOADING_STATE.IDLE;

      const { results, next, previous } = action.payload;

      projectAdapter.setAll(state, results);
      state.next = next;
      state.prev = previous;
    });
    builder.addCase(getExtendedProject.fulfilled, (state, action) => {
      state.error = null;
      state.loading = LOADING_STATE.PENDING;

      const { as_builts, billing, job_packet_returned, superintendents, wmo_fco, project_jobs, job_costing } = action.payload;

      state.project = action.payload;
      state.projectAsBuilts = as_builts;
      state.projectBilling = billing;
      state.projectJobPacket = job_packet_returned;
      state.projectSuperintendents = superintendents;
      state.projectFcos = wmo_fco;
      state.projectJobs = project_jobs;
      state.projectJobCosting = job_costing;
    });

    // UPDATE
    builder.addCase(patchProjectSuperintendent.fulfilled, (state, action) => {
      const { project, superintendents } = action.payload;

      state.projectSuperintendents = superintendents;
      state.project = project;
    });
    builder.addCase(patchProject.fulfilled, (state, action) => {
      // TODO: Test if this works
      const { fingerprint, update } = action.payload;
      // Update list of projects for project selector and project table
      projectAdapter.updateOne(state, { id: fingerprint, changes: update });
      // Update project data in state. Project data in state is what's used for rendering form values
      // TODO: Check if returned data is extended project data
      state.project = {
        ...state.project,
        ...update
      };
    });
    builder.addCase(patchProjectBillingInvoice.fulfilled, (state, action) => {
      const { project, billing } = action.payload;
      state.projectBilling = billing;
      // instead of making another request to update the project data, we'll just manually update the state
      state.project = project;
    });
    builder.addCase(patchProjectAsBuilt.fulfilled, (state, action) => {
      const { project, as_builts } = action.payload;

      state.projectAsBuilts = as_builts;
      state.project = project;
    });
    builder.addCase(patchProjectJob.fulfilled, (state, action) => {
      const { project, project_jobs } = action.payload;

      state.projectJobs = project_jobs;
      state.project = project;
    });
    builder.addCase(patchProjectJobPacket.fulfilled, (state, action) => {
      const { project, job_packet_returned } = action.payload;

      state.projectJobPacket = job_packet_returned;
      state.project = project;
    });
    builder.addCase(patchProjectJobCosting.fulfilled, (state, action) => {
      const { project, job_costing } = action.payload;

      state.projectJobCosting = job_costing;
      state.project = project;
    });
    builder.addCase(patchProjectFcos.fulfilled, (state, action) => {
      const { project, wmo_fco } = action.payload;

      state.projectFcos = wmo_fco;
      state.project = project;
    });
  }
});

export default slice.reducer;

export const { setProject, clearProject } = slice.actions;

// READ
export const getProjects = createAsyncThunk('projects/getProjects', async (_, { rejectWithValue }) => {
  try {
    const response = await projectApi.getProjects();

    if (!response.ok) {
      return rejectWithValue(response.problem);
    }

    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getExtendedProject = createAsyncThunk('projects/getExtendedProject', async ({ fingerprint }, { rejectWithValue }) => {
  try {
    const response = await projectApi.getExtendedProject({ fingerprint });

    if (!response.ok) {
      return rejectWithValue(response.problem);
    }

    // Add project in local storage
    // When refreshing the page, we'll look first into local storage if there's a project stored
    // if there is, we'll use that in HeaderContext to set current project data instead of calling on this action
    localStorage.setItem('project', JSON.stringify(response.data));

    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

// UPDATE
export const patchProjectSuperintendent = createAsyncThunk('projects/patchProjectSuperintendent', async ({ fingerprint, payload }) => {
  try {
    const response = await projectApi.patchProjectSuperintendent({ fingerprint, payload });

    if (!response.ok) {
      return rejectWithValue(response.problem);
    }

    const updatedProject = getUpdatedProjectFromStorage('superintendents', response.data);
    localStorage.setItem('project', JSON.stringify(updatedProject));

    return {
      project: updatedProject,
      superintendents: action.payload
    };
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const patchProject = createAsyncThunk('projects/patchProject', async ({ fingerprint, payload }) => {
  try {
    const response = await projectApi.patchProject({ fingerprint, payload });

    if (!response.ok) {
      return rejectWithValue(response.problem);
    }

    // Update project data in local storage
    // Assuming returned data is the updated project data
    localStorage.setItem('project', JSON.stringify(response.data));

    return { fingerprint, update: response.data };
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const patchProjectBillingInvoice = createAsyncThunk('projects/patchProjectBillingInvoice', async ({ fingerprint, payload }) => {
  try {
    const response = await projectApi.patchProjectBillingInvoice({ fingerprint, payload });

    if (!response.ok) {
      return rejectWithValue(response.problem);
    }

    const updatedProject = getUpdatedProjectFromStorage('billing', response.data);
    localStorage.setItem('project', JSON.stringify(updatedProject));

    return {
      project: updatedProject,
      billing: action.payload // TODO: Verify if this is an array of billing invoices or just the updated one
    };
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const patchProjectAsBuilt = createAsyncThunk('projects/patchProjectAsBuilt', async ({ fingerprint, payload }) => {
  try {
    const response = await projectApi.patchProjectAsBuilt({ fingerprint, payload });

    if (!response.ok) {
      return rejectWithValue(response.problem);
    }

    const updatedProject = getUpdatedProjectFromStorage('as_builts', response.data);
    localStorage.setItem('project', JSON.stringify(updatedProject));

    return {
      project: updatedProject,
      as_builts: action.payload // TODO: Verify if this is an array of as_builts or just the updated one
    };
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const patchProjectJob = createAsyncThunk('projects/patchProjectJob', async ({ fingerprint, payload }) => {
  try {
    const response = await projectApi.patchProjectJob({ fingerprint, payload });

    if (!response.ok) {
      return rejectWithValue(response.problem);
    }

    const updatedProject = getUpdatedProjectFromStorage('project_jobs', response.data);
    localStorage.setItem('project', JSON.stringify(updatedProject));

    return {
      project: updatedProject,
      project_jobs: action.payload // TODO: Verify if this is an array of project jobs or just the updated one
    };
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const patchProjectJobPacket = createAsyncThunk('projects/patchProjectJobPacket', async ({ fingerprint, payload }) => {
  try {
    const response = await projectApi.patchProjectJobPacket({ fingerprint, payload });

    if (!response.ok) {
      return rejectWithValue(response.problem);
    }

    const updatedProject = getUpdatedProjectFromStorage('job_packet_returned', response.data);
    localStorage.setItem('project', JSON.stringify(updatedProject));

    return {
      project: updatedProject,
      job_packet_returned: action.payload // TODO: Verify if this is an array of job packets or just the updated one
    };
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const patchProjectJobCosting = createAsyncThunk('projects/patchProjectJobCosting', async ({ fingerprint, payload }) => {
  try {
    const response = await projectApi.patchProjectJobCosting({ fingerprint, payload });

    if (!response.ok) {
      return rejectWithValue(response.problem);
    }

    const updatedProject = getUpdatedProjectFromStorage('job_costing', response.data);
    localStorage.setItem('project', JSON.stringify(updatedProject));

    return {
      project: updatedProject,
      job_costing: action.payload // TODO: Verify if this is an array of job costs or just the updated one
    };
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const patchProjectFcos = createAsyncThunk('projects/patchProjectFcos', async ({ fingerprint, payload }) => {
  try {
    const response = await projectApi.patchProjectFcos({ fingerprint, payload });

    if (!response.ok) {
      return rejectWithValue(response.problem);
    }

    const updatedProject = getUpdatedProjectFromStorage('wmo_fco', response.data);
    localStorage.setItem('project', JSON.stringify(updatedProject));

    return {
      project: updatedProject,
      wmo_fco: action.payload // TODO: Verify if this is an array of fcos or just the updated one
    };
  } catch (err) {
    rejectWithValue(err);
  }
});

// selectors
export const { selectById: selectProjectByFingerprint, selectAll: selectAllProjects } = projectAdapter.getSelectors(
  (state) => state.projects
);

export const selectActiveProject = createSelector([(state) => state.projects.project], (projectSelected) => projectSelected);

export const selectProjectSuperintendent = createSelector(
  [(state) => state.projects.projectSuperintendents],
  (projectSuperintendents) => projectSuperintendents[0] ?? null
);

export const selectProjectBilling = createSelector(
  [(state) => state.projects.projectBilling],
  (projectBilling) => projectBilling[0] ?? null
);

export const selectProjectAsBuilt = createSelector(
  [(state) => state.projects.projectAsBuilts],
  (projectAsBuilts) => projectAsBuilts[0] ?? null
);

export const selectProjectJob = createSelector([(state) => state.projects.projectJobs], (projectJobs) => projectJobs[0] ?? null);

export const selectProjectJobPacket = createSelector(
  [(state) => state.projects.projectJobPacket],
  (projectJobPacket) => projectJobPacket[0] ?? null
);

export const selectProjectJobCosting = createSelector(
  [(state) => state.projects.projectJobCosting],
  (projectJobCosting) => projectJobCosting[0] ?? null
);

export const selectProjectFcos = createSelector([(state) => state.projects.projectFcos], (projectFcos) => projectFcos ?? null);
