import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'store';

// material-ui
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Box, Container, Grid } from '@mui/material';

// project import
import Drawer from './Drawer';
import navigation from 'menu-items';
import useConfig from 'hooks/useConfig';
import Breadcrumbs from 'components/@extended/Breadcrumbs';
import { Header } from './Header';
import { SideForm } from './SideForm';
import { openDrawer } from 'store/reducers/menu';
import { HeaderProvider } from 'contexts/HeaderContext';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const { container, miniDrawer } = useConfig();
  const dispatch = useDispatch();

  const menu = useSelector((state) => state.menu);
  const { drawerOpen, openItem } = menu;

  // currently, openItem will only have one item in it. If in the feature openItem would have multiple values, update this logic
  // openItem being an array is from mantis template. Not updating its value for now to avoid unnecessary refactor
  const showSideForm = openItem[0] === 'dashboard' || openItem[0] === 'reports';

  // drawer toggler
  const [open, setOpen] = useState(!miniDrawer || drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // set media wise responsive drawer
  useEffect(() => {
    if (!miniDrawer) {
      setOpen(!matchDownLG);
      dispatch(openDrawer({ drawerOpen: !matchDownLG }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  return (
    <HeaderProvider>
      <Box sx={{ display: 'flex', width: '100%', minHeight: '100vh' }}>
        <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
        <Box component="main" sx={{ width: 'calc(100% - 260px)', flexGrow: 1, backgroundColor: '#fff', display: 'flex' }}>
          {container && (
            <Container
              maxWidth={false}
              sx={{
                width: '100%',
                px: { xs: 0 },
                position: 'relative',
                flex: 1,
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Header open={open} handleDrawerToggle={handleDrawerToggle} />
              {matchesXs && showSideForm && <SideForm isMobile />}
              <Grid container sx={{ flex: 1 }}>
                {/* 75px is arbitrary value representing the header height */}
                <Grid item sx={{ maxHeight: 'calc(100vh - 75px)', width: { xs: '100%', md: 'calc(100% - 260px)' } }}>
                  <Outlet />
                </Grid>
                {!matchesXs && showSideForm && (
                  <Grid item sx={{ width: '260px', maxHeight: 'calc(100vh - 75px)' }}>
                    <SideForm />
                  </Grid>
                )}
              </Grid>
            </Container>
          )}
          {!container && (
            <Box sx={{ position: 'relative', minHeight: 'calc(100vh - 110px)', display: 'flex', flexDirection: 'column' }}>
              <Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} />
              <Outlet />
            </Box>
          )}
        </Box>
      </Box>
    </HeaderProvider>
  );
};

export default MainLayout;
