import { useState, useEffect } from 'react';

// redux
import { useDispatch } from 'store';

// utils
import { getValuesFromData } from 'utils/getValuesFromData';

const useForm = (resource, action) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    if (resource) {
      setFormData(getValuesFromData(resource));
    } else {
      setFormData(null);
    }
  }, [resource]);

  const handleChange = (field, val) => {
    setFormData({
      ...formData,
      [field]: val
    });
  };

  const handleBlur = (data) => {
    dispatch(action(data));
  };

  return [formData, handleChange, handleBlur];
};

export default useForm;
