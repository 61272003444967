import { useRoutes } from 'react-router-dom';

// project import
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import AdminRoutes from './AdminRoutes';
import NotFound from './NotFound';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  // TODO: Will later be determined from logged in user's info
  const isAdmin = false;

  // Conditionally adding admin routes prevents users from hard-coding the url
  return useRoutes([LoginRoutes, MainRoutes, isAdmin && AdminRoutes, NotFound]);
}
