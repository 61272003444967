// third-party
import { FormattedMessage } from 'react-intl';

// assets
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

// icons
const icons = {
  PeopleAltIcon,
  AdminPanelSettingsIcon
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const admin = {
  id: 'other',
  type: 'group',
  isAdminOnly: true,
  children: [
    {
      id: 'admin',
      title: <FormattedMessage id="admin" />,
      type: 'collapse',
      url: '/admin',
      icon: icons.AdminPanelSettingsIcon,
      children: [
        {
          id: 'users',
          title: <FormattedMessage id="users" />,
          type: 'item',
          url: '/admin/users',
          icon: icons.PeopleAltIcon
        }
      ]
    }
  ]
};

export default admin;
