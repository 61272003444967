import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// pages
const Dashboard = Loadable(lazy(() => import('pages/dashboard')));
const Reports = Loadable(lazy(() => import('pages/reports')));

// render - dummy page
const DummyPage = Loadable(lazy(() => import('components/DummyPage')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'dashboard',
          element: <Dashboard />
        },
        {
          path: 'reports',
          element: <Reports />
        },
        {
          path: 'tracking',
          element: <DummyPage title="Tracking" />
        },
        {
          path: 'groups',
          element: <DummyPage title="Groups" />
        },
        {
          path: 'support',
          element: <DummyPage title="Support" />
        },
        {
          path: 'settings',
          element: <DummyPage title="Settings" />
        },
        {
          path: 'projects/project-sub-link-1',
          element: <DummyPage title="Project Sublink 1" />
        },
        {
          path: 'projects/project-sub-link-2',
          element: <DummyPage title="Project Sublink 2" />
        },
        {
          path: 'projects/project-sub-link-3',
          element: <DummyPage title="Project Sublink 3" />
        }
      ]
    }
  ]
};

export default MainRoutes;
